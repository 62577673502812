<template>
  <section class="m-order m-scroll" ref="scroll">
    <!-- 头部导航 -->
    <m-header title="我的订单" >
      <img slot="right" src="@/assets/order/icon_gouwu@2x.png" alt="cart" @click="$router.push('/zh/cart')">
    </m-header>

    <!-- 标签页 -->
    <div class="order-tabs">
      <van-tabs v-model="tab" class="tab-item" @click="handleTab" title-active-color="#ee0a24">
        <van-tab title="全部" name="0"></van-tab>
        <van-tab title="待付款" name="1"></van-tab>
        <van-tab title="待发货" name="2"></van-tab>
        <van-tab title="待收货" name="3"></van-tab>
        <van-tab title="退款售后" name="4"></van-tab>
      </van-tabs>
      <div class="tab-filter" @click="handleFilter"><img src="@/assets/order/icon_shaixuan@2x.png" alt=""></div>
    </div>

    <!-- 订单列表 -->
    <van-loading v-show="vLoading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
    <van-list  v-model="loading" :finished="finished"  @load="onLoad" :immediate-check="false" class="scroll-list">
        <order-list :orderList="orderList" :tab="tab" :loading="vLoading"></order-list>
    </van-list>

    <!-- 筛选弹窗 -->
    <van-popup v-model="filter" get-container="#app"  position="right" close-on-popstate class="filter-order" >
      <p class="filter-title van-hairline--bottom">时间筛选</p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 0}" @click="handleMonth(0)">全部订单</span></p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 1}" @click="handleMonth(1)">最近一个月</span></p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 3}" @click="handleMonth(3)">最近三个月</span></p>
      <p class="filter-item"><span class="item" :class="{'filter-active':month == 6}" @click="handleMonth(6)">最近六个月</span></p>

    </van-popup>

    <!-- 游客查看 -->
    <div class="guest-order" v-if="guest">
      <div class="view-button" @click="$router.push('/zh/search_order')">游客查单</div>
    </div>
  </section>
</template>

<script>
import MHeader from '@/components/zh/m-header.vue'
import OrderList from '@/components/zh/order-list.vue'
import { getOrder, getAftersale } from '@/api/zh/order.js'
import { isGuest } from '@/api/zh/mine.js'

import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ MHeader, OrderList },
  data() {
    return {
      tab:0,          // tabs选中的面板
      filter:false,   // 筛选面板显示
      month: '0',     // 筛选的结果 
      page: 1,
      orderList: [],
      goodsId: '',
      loading: false,
      vLoading: false,
      finished: false,
      guest: false
    }
  },
  activated(){
    this.tab = this.$route.params.tab
    this.handleTab(this.tab)

    this.isGuestHandle()
  },
  beforeRouteEnter (to, from, next) {
    if(from.path == '/zh/paystatus') {
      next(vm => {
        vm.islock = false
        vm.handleTab(0)
      })
    } else {
      next()
    }
  },
  methods: {
    // 切换 tab
    handleTab(tab){
      this.vLoading = true
      this.tab = tab
      this.orderList = []
      this.page = 1
      this.$refs.scroll.scrollTop = 0

      if(tab != 4) {
        this.getOrderHandle()
      } else {
        this.getAftersaleHandle()
      }
    },
    // 点击筛选 tab
    handleFilter(){
      this.filter = true
    },
    // 点击月份筛选
    handleMonth(month){
      if(this.month == month) {
        this.month = ''
      } else {
        this.month = month
      }
      let timer = setTimeout( () => {
        this.filter = false
        this.orderList = []
        this.page = 1
        this.getOrderHandle()
        clearTimeout(timer)
      }, 300)
    },

 
    // 为您推荐 加载更多
    onLoad(){
      if(!this.finished) {
        this.page ++ 
        this.getOrderHandle()
      }
    },

    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        clearTimeout(timer)
      },300)
      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getOrderHandle() {
      // 获取订单
      let params = {
        month: this.month,
        status: this.tab,
        page: this.page
      }
      getOrder(params).then(res => {
        if(res) {
          if(this.page == 1) {
            this.orderList = []
          }
          this.orderList = [...this.orderList, ...res.data.data]
          this.loading = false
        }else {
          this.finished = true
        }
      }).finally(() => {
        this.vLoading = false
      })
    },
    getAftersaleHandle() {
      // 获取售后列表
      let params = {
        month: this.month,
        status: this.tab,
        page: this.page
      }
      getAftersale(params).then(res => {
        if(res) {
          this.orderList = [...this.orderList, ...res.data.data]
          this.loading = false
        }else {
          this.finished = true
        }
      }).finally(() => {
        this.vLoading = false
      })
    },

    isGuestHandle() {
      // 是否市游客
      isGuest().then(res => {
        if(res) {
          this.guest = res.status == 1 ? true : false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './list.less';

.guest-order{position: fixed;bottom:0;left:0;width:100%;height:50px;background-color: #fff;border-top:1px soild #f1f1f1;z-index: 99;;
  .view-button{margin:7px auto;width:300px;height:36px;background: linear-gradient(315deg,#F03C18,#F06448);
  border-radius: 36px;text-align: center;line-height: 36px;color:#fff;font-size: 16px;}
}
.van-loading {
  margin: 15vw 0 4vw;
}
</style>