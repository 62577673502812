<template>
  <ul class="order-list">
    <li class="order-item" v-for="(item, index) in orderList" :key="index">
      <div @click="goToDetail(item)">
        <div class="item-head m-padding">
          <div class="merchant">
            <img src="@/assets/order/mooby.png" alt="merchant" v-if="item.shops_id == 1">
            <img src="@/assets/order/shop.png" alt="merchant" v-if="item.shops_id != 1">
            <p class="van-ellipsis">{{item.shops_title}}
              <span>(共{{(item.order_goods || item.order_goods_all).length}}件)</span>
              <span>{{item.created_at}}</span>
            </p>
          </div>
          <div class="detail">查看订单</div>
        </div>

        <div class="item-content van-hairline--top van-hairline--bottom" v-for="(it, ind) in (item.order_goods || item.order_goods_all)" :key="ind">
          <div class="content-left">
            <img :src="it.goods_sku_pic_url?it.goods_sku_pic_url:'/moren.png'" alt="goods">
          </div>
          <div class="content-right">
            <p class="goods-title van-ellipsis">
              <span class="m-mooby" style="width:48px" v-if="it.is_presale == 1">预售</span>
              {{it.goods_name}}
            </p>
            <div class="goods-info">
              <span class="goods-sku" v-if="it.sku_name">{{it.sku_name.split(',').join(' | ')}}</span>
              <span class="goods-status">{{item.order_status_title}}</span>
            </div>
            <div class="goods-price">{{item.order_logistics.country == 507 ? 'US$':'C$'}}{{it.goods_price}}</div>
          </div>
        </div>

        <div class="item-foot m-padding">
          <div class="price" v-if="tab != 4">应付：<span>{{item.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{item.order_total_amount}}</span></div>
          <div class="price" v-else>售后状态：<span></span><span>{{item.audit_status_title}}</span></div>
          <div class="btns"> 
            <span class="btn-acitve" @click.prevent.stop="onPay(item)" v-if="item.order_status == 0">继续支付</span>
            <span class="btn" @click.prevent.stop="onCancel(item)" v-if="item.order_status == 0">取消订单</span>
            <span @click.prevent.stop="$router.push(`/zh/aftersale/select/${item.id}`)" class="btn-acitve"
            v-if="item.order_status == 2 || item.order_status == 1 || item.order_status == 3">申请售后</span>
            <span class="btn-acitve" @click.prevent.stop="onComment(item.id)" v-if="item.order_status == 3 && item.evaluate_status == 0">评价订单</span>
            <!-- <span class="btn-acitve" @click.prevent="onPay()" v-if="item.order_status == 4">再次购买</span> -->
            <span style="color:#888;font-size:14px" v-if="tab == 4">{{item.reason}}</span>
          </div>
        </div>

      </div>
    </li>
    <p v-if="orderList.length == 0 && !loading" style="font-size:12px;color:#888;line-height:200px;text-align:center">暂无数据</p>
  </ul>
</template>

<script>
import { cancelOrder } from '@/api/zh/order.js'
export default {
  name: "OrderList",
  props: ['orderList', 'tab', 'loading'],
  data(){
    return {
      times:72,
      time:'',
      timer:null
    }
  },

  created(){
    this.handleTime()
  },

  methods: {
    goToDetail(item) {
      if(item.order_id) {
        sessionStorage.setItem('aftersale', JSON.stringify(item))
      }
      this.$router.push(`/zh/${item.order_id ? 'aftersale' : 'order'}/detail/${item.id}`)
    },
    handleBack(){
      this.$router.go(-1)
    },
    handleTime(){
       this.timer = setInterval( () => {
         let m =  Math.floor(this.times / 60)
         let s = this.times % 60
         
         if(m == 0 && s == 0){
           clearInterval(this.timer)
         }
         this.times -- 
         this.time =  `${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`
       }, 1000)
    },
    onPay(order){
      localStorage.setItem('order', JSON.stringify(order))
      if(order.order_logistics && order.order_logistics.country == 507) {
        this.$router.replace({path: '/zh/usPay', query: {id: order.id, delivery_method: order.delivery_method}})
      } else {
        this.$router.replace({path: '/zh/pay', query: {id: order.id, delivery_method: order.delivery_method}})
      }
      
      if(order.order_goods[0].goods_id == '3751') {
        localStorage.setItem('bargain', 1)
      }
    },
    // 取消订单
    onCancel(item) {
      cancelOrder(item.id).then(res => {
        if(res) {
           this.$notify({ type: 'success', message: res.msg })
           item.order_status = 4
           item.order_status_title = '交易关闭'
        }
      })
    },
    onComment(id) {
      this.$router.push({path: '/zh/comment/submit', query: {id: id}})
    }
  },
  destroyed(){
    clearInterval(this.timer)
  }
}
</script>

<style lang="less" scoped>
.order-list {
  width: 100%;
  min-height:200px;
  background-color: #fff;
  z-index: 9;
  margin-top: 50px;
  .order-item {
    margin-top: 10px;
    box-shadow: 0px 10px 10px 0 rgba(217, 217, 217, 0.15);
    .item-head {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .merchant {
        height: 64px;
        display: flex;
        align-items: center;
        flex: 1;
        &>img{
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-right: 5px;
          border-radius: 50%;
          box-shadow: 0px 1px 4px rgba(102, 102, 102, 0.1);          
        }
        &>p {
          font-size:16px;
          font-weight:500;
          width: 100%;
          color:rgba(12,2,29,1);
          &>span {
            display: inline-block;
            font-size: 12px;
          }
          &>span:nth-child(2) {
            margin-top: 2px;
            display: block;
            color: #666;
            font-size: 12px;
          }
        }
      }
      .detail {
        font-size:14px;
        font-weight:400;
        color:#3284FA;
        height: 28px;
        width: 80px;
        text-align: center;
        line-height: 28px;
        border-radius: 14px;
        border:1px solid #3284FA;
      }
    }
    .item-content {
      margin-left: 16px;
      height: 103px;
      display: flex;
      align-items: center;
      .content-left {
        width: 80px;
        height: 80px;
        margin-right: 12px;
        border-radius: 5px;
        &>img {
          width: 80px;
          height: 80px;
        }
      }
      .content-right {
        flex: 1;
        height: 80px;
        .goods-title{
          height: 20px;
          line-height: 20px;
          padding-right: 50px;
          width: 245px;
          font-size:16px;
          font-weight:400;
          color:rgba(51,51,51,1);
        }
        .goods-info {
          width: 100%;
          padding-right: 16px;
          display: flex;
          align-content: center;
          justify-content: space-between;
          margin-top: 8px;
          .goods-sku {
            // height: auto;
            display: inline-block;
            background:rgba(245,245,245,1);
            border-radius:3px;
            padding: 0 5px;
            font-size:12px;
            font-weight:400;
            color:rgba(102,102,102,1);
            height: 18px;
            line-height: 18px;
          }
          .goods-status {
            height: 26px;
            line-height: 26px;
            font-size:14px;
            font-weight:400;
            color:rgba(237,42,36,1);
          }
        }
        .goods-price {
          font-size:14px;
          font-weight:500;
          color:rgba(51,51,51,1);
          line-height:18px;
          margin-top: 8px;
        }
      }
    }
    .item-foot {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      justify-items: flex-end;
      .price {
        font-size:14px;
        font-weight:400;
        color:rgba(51,51,51,1);
        &>span:nth-of-type(1){
          font-size: 10px;
          color: #ED2A24;
        }
        &>span:nth-of-type(2){
          color: #ED2A24;
        }
      }
      .btns {
        .btn-acitve {
          padding: 5px 12px;
          font-size: 14px;
          color: #ED2A24;
          border: 1px solid #ED2A24;
          border-radius: 35px;
        }
        .btn-acitve + .btn-acitve, .btn-acitve + .btn {
          margin-left: 5px;
        }
        .btn {
          padding: 5px 12px;
          font-size: 14px;
          color: #333333;
          border: 1px solid #979797;
          border-radius: 35px;
        }
      }
    }
  }
  
}
</style>